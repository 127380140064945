//
//  Sweetalert2
//

/* CSS Switch */
input[switch] {
  display: none;
  + label {
    font-size: 1em;
    line-height: 1;
    width: 56px;
    height: 24px;
    background-color: $gray-400;
    background-image: none;
    border-radius: 2rem;
    padding: 0.16667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    font-weight: $font-weight-medium;
    transition: all 0.1s ease-in-out;
    &:before {
      color: $dark;
      content: attr(data-off-label);
      display: block;
      font-family: inherit;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      position: absolute;
      right: 1px;
      margin: 3px;
      top: -2px;
      text-align: center;
      min-width: 1.66667rem;
      overflow: hidden;
      transition: all 0.1s ease-in-out;
    }

    &:after {
      content: "";
      position: absolute;
      left: 3px;
      background-color: $gray-200;
      box-shadow: none;
      border-radius: 2rem;
      height: 20px;
      width: 20px;
      top: 2px;
      transition: all 0.1s ease-in-out;
    }
  }

  &:checked + label {
    background-color: $primary;
  }
}

input[switch]:checked + label {
  background-color: $primary;
  &:before {
    color: $white;
    content: attr(data-on-label);
    right: auto;
    left: 3px;
  }

  &:after {
    left: 33px;
    background-color: $gray-200;
  }
}

input[switch].switcher + label {
  background-color: $primary !important;

  &:before {
    color: $white !important;
  }

  &:after {
    background-color: $gray-200 !important;
  }
}

input[switch="bool"] + label {
  background-color: $danger;
}
input[switch="bool"] + label:before,
input[switch="bool"]:checked + label:before,
input[switch="default"]:checked + label:before {
  color: $white;
}

input[switch="bool"]:checked + label {
  background-color: $success;
}

input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}

input[switch="primary"]:checked + label {
  background-color: $primary;
}

input[switch="success"]:checked + label {
  background-color: $success;
}

input[switch="info"]:checked + label {
  background-color: $info;
}

input[switch="warning"]:checked + label {
  background-color: $warning;
}

input[switch="danger"]:checked + label {
  background-color: $danger;
}

input[switch="dark"]:checked + label {
  background-color: $dark;
}

.square-switch {
  margin-right: 7px;
  input[switch] + label,
  input[switch] + label:after {
    border-radius: 4px;
  }
}

/* CSS Status Switch */
.status-switch {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 105px;
    height: 36px;

    &:before {
      top: 4px;
    }

    &:after {
      height: 30px;
      width: 30px;
      top: 3px;
    }
  }

  input[switch]:checked + label {
    &:after {
      left: 73px;
    }
  }
}

/* CSS Status Switch */
.switch-xsm {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 42px;

    &:before {
      right: -3px;
    }

    &:after {
      width: 10px;
    }
  }

  input[switch]:checked + label {
    &:before {
      left: -18px;
    }

    &:after {
      left: 30px;
    }
  }
}

/* CSS Status Switch */
.switch-sm {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 72px;
    height: 36px;

    &:before {
      top: 4px;
    }

    &:after {
      height: 30px;
      width: 30px;
      top: 3px;
    }
  }

  input[switch]:checked + label {
    &:after {
      left: 40px;
    }
  }
}

/* CSS Status Switch */
.switch-l {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 92px;
    height: 36px;

    &:before {
      top: 4px;
    }

    &:after {
      height: 30px;
      width: 30px;
      top: 3px;
    }
  }

  input[switch]:checked + label {
    &:after {
      left: 60px;
    }
  }
}

/* CSS Status Switch */
.switch-xl {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 112px;
    height: 36px;

    &:before {
      top: 4px;
    }

    &:after {
      height: 30px;
      width: 30px;
      top: 3px;
    }
  }

  input[switch]:checked + label {
    &:after {
      left: 80px;
    }
  }
}

/* CSS Status Switch */
.switch-xxl {
  margin-right: 0;

  input[switch] + label {
    margin-bottom: 0;
    width: 127px;
    height: 36px;

    &:before {
      top: 4px;
    }

    &:after {
      height: 30px;
      width: 30px;
      top: 3px;
    }
  }

  input[switch]:checked + label {
    &:after {
      left: 95px;
    }
  }
}
