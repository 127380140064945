//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.dropzone-sm {
  min-height: 130px;

  .dz-message {
    font-size: 18px;
    padding: 10px;
  }
}

.dropzone-previews .close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  float: right;
  height: 100%;
  display: flex;
  align-items: center;

  .close-content {
    background-color: #d0e5f5;
    color: #000;
    cursor: pointer;
    border-radius: 50%;
    padding: 4px 6px;
    font-size: 1.21875rem;
    font-weight: 600;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;

    .close-conten:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.dropzone-single-image {
  position: relative;
  cursor: pointer;

  .edit {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    font-size: 20px;

    i {
      color: white;
    }
  }

  :has(img.rounded) {
    .edit {
      right: 0;
      bottom: 0;
    }
  }

  &:focus {
    outline: none;
  }
}
