.spinner-content {
  .spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    justify-content: center;
    display: flex;
    align-items: center;
    // -webkit-backdrop-filter: blur(0.5px);
    // backdrop-filter: blur(0.5px);
  }
}
