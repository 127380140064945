:root {
  --primary-font: "Jost", sans-serif;
  --secondary-font: "Poppins", sans-serif;
  --h1-size: 4rem;
  --h1-weight: 900;
  --h2-size: 3rem;
  --h2-weight: 700;
  --h3-size: 2rem;
  --h3-weight: 600;
  --h4-size: 1.5rem;
  --h4-weight: 600;
  --h5-size: 1.2rem;
  --h5-weight: 600;
  --h6-size: 1rem;
  --h6-weight: 600;
  --p-size: 1rem;
  --p-weight: 400;
  --header-bg-color: #121117;
  --nav-brand-height: 55px;
  --top-nav-item-color: #f5f5f5;
  --nav-item-color: #121117;
  --primary-color: #378f88;
  --gradient-bg: linear-gradient(
    90deg,
    unicornstrong 10%,
    #00a0f3 39%,
    #d424a5 98%
  );
  --secondary-color: #00a0f3;
  --white-color: #f9f9f9;
  --black-color: #040402;
  --grey-color: #f3f5f7;
  --dark-grey-color: #191919;
  --primary-t-color: #f5f5f5;
  --secondary-t-color: #f5f5f5;
  --primary-p-color: #8e8e8e;
  --secondary-p-color: #e1e1e1;
  --primary-b-color: #f5f5f5;
  --primary-l-color: rgba(0, 0, 0, 0.12);
  --secondary-l-color: rgba(255, 255, 255, 0.12);
  --valid-color: #007a4d;
  --invalid-color: #e34f4f;
  --primary-bg-color: #09080d;
  --primary-bg-color-2: #eeeeee;
  --primary-bg-color-3: #e5e5e5;
  --secondary-bg-color: #040402;
  --secondary-bg-color-2: #111111;
  --secondary-bg-color-3: #191919;
  --card-bg-color: #16151a;
  --footer-bg-color: #121117;
}
.auth-body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  // background-color: blue;
  text-align: left;
  //  background : var(--primary-bg-color) !important;
  background: linear-gradient(270deg, #378f88 33.52%, #1c3784 85.22%);

  a:focus,
  button:focus {
    text-decoration: none;
    outline: none;
  }
  a:hover {
    transition: all 0.5s ease 0s;
  }

  a:hover {
    text-decoration: none;
  }

  .themebtnblack {
    width: 101px;
    height: 39px;
    line-height: normal;
    background: #292f30;
    color: #fff;
    border: 1px solid #292f30;
    border-radius: 5px;
    font-size: 18px;
    margin-left: 16px;
    transition: all 0.3s ease-out;
    margin-top: 5px;
  }
  .themebtnblack:hover {
    box-shadow: 2px 2px 11px 0 #bfc5c663;
    -webkit-box-shadow: 2px 2px 11px 0 #bfc5c663;
    border: 1px solid #378f88;
    // background : var(--primary-color3) !important;
    color: #fff;
  }

  .themebtn {
    width: max-content;
    height: 43px;
    line-height: normal;
    // background: #378f88;
    // background: var(--gradient-bg) !important;
    // color: var(--primary-color3) !important;
    color: #fff !important;
    border: 1px solid var(--primary-color3);
    border-radius: 5px;
    font-size: 17px;
    transition: all 0.3s ease-out;
    padding: 00px 20px;
  }

  .themebtn-unfilled {
    width: max-content;
    height: 43px;
    line-height: normal;
    background: #fff;
    color: #378f88;
    border-radius: 5px;
    font-size: 17px;
    transition: all 0.3s ease-out;
    padding: 00px 30px;
    border: 0px;
    box-shadow: 0px 2px 7px 0px rgba(33, 33, 33, 0.15);
  }

  .flex-button button {
    margin: 00px 6px;
  }
  .flex-button {
    margin-top: 49px;
  }
  .themebtn:hover {
    background: var(--primary-color3) !important;
    color: var(--primary-color) !important;
    // border: 1px solid #378f88;
    // box-shadow: 2px 2px 11px 0 #131c3785;
    // -webkit-box-shadow: 2px 2px 11px 0 #131c3785;
  }
  .new_ho:hover {
    background: var(--primary-color) !important;
    color: #fff !important;
    // border: 1px solid #378f88;
    // box-shadow: 2px 2px 11px 0 #131c3785;
    // -webkit-box-shadow: 2px 2px 11px 0 #131c3785;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 29px;
    color: #6f6f6f;
    letter-spacing: 0.36px;
    font-family: "FSElliotPro";
  }
  #banner p {
    margin-bottom: 18px;
  }
  #banner h1 {
    padding-top: 0;
    margin-bottom: 12px;
  }
  h1 {
    font-size: 46px;
    line-height: 58px;
    color: #292f30;
    font-family: "Europa-Bold";
  }

  .d-solutions-wrap {
    text-align: center;
  }

  .center {
    margin: 0 auto;
    display: block;
  }
  .heading {
    font-size: 40px;
    line-height: 54px;
    color: #292f30;
    letter-spacing: normal;
    position: relative;
    font-family: "Europa-Bold";
    padding-bottom: 12px;
  }
  .heading::after {
    content: "";
    position: absolute;
    width: 66px;
    height: 3px;
    background: #378f88;
    left: 0;
    bottom: 0;
  }

  .flexcenter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
  }
  .paragraph {
    padding-top: 10px;
  }
  h4 {
    color: #378f88;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1.14px;
    margin: 0;
  }

  .mt-50 {
    margin-top: 30px;
  }

  .carousel-wrap {
    margin: 90px auto;
    padding: 0 5%;
    width: 80%;
    position: relative;
  }

  /* fix blank or flashing items on carousel */
  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  /* end fix */
  .owl-nav > div {
    margin-top: -26px;
    position: absolute;
    top: 50%;
    color: #cdcbcd;
  }

  .owl-nav i {
    font-size: 52px;
  }

  .owl-nav .owl-prev {
    left: -30px;
  }

  .owl-nav .owl-next {
    right: -30px;
  }
  .client-image img {
    width: 45px !important;
    height: 45px;
    border-radius: 50%;
  }

  .top-text-wrap {
    padding-top: 37px;
    padding-left: 85px;
  }
  .qomas {
    width: 64px !important;
    height: 49px;
    margin-top: 64px;
  }

  .owl-dots {
    display: block !important;
    margin-top: 36px;
  }

  .slick-dots li {
    margin-right: 15px !important;
  }

  .owl-dot span,
  .slick-dots li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background: transparent;
    border: 1px solid #292f30;
    margin-right: 15px;
    opacity: 1 !important;
  }

  .slick-dots .slick-active {
    background: #378f88;
  }

  .owl-dot.active span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    background: #378f88;
    border: 1px solid #378f88;
    margin-right: 15px;
    opacity: 1 !important;
  }

  .social-media {
    display: flex;
  }
  .social-media a {
    font-size: 17px;
    color: #292f30;
    padding-right: 15px;
  }
  .social-media a i {
    font-size: 21px;
  }
  .mt-38 {
    margin-top: 27px;
  }
  .input-group {
    padding: 11px 00px;
    display: flex;
    justify-content: space-between;
  }
  input.form-controls {
    padding: 10px 14px;
    background: transparent;
    border: 1px solid #378f88;
    width: 100%;
  }
  input.form-controls:focus {
    outline: none;
  }
  footer .themebtn {
    height: 40px;
    line-height: normal;
    background: #378f88;
    color: #fff;
    border: 1px solid #378f88;
    border-radius: 5px;
    font-size: 17px;
    transition: all 0.3s ease-out;
    width: 100%;
    margin-top: 11px;
  }
  .submitbtn {
    border: 0;
    background: #378f88;
    color: #fff;
    font-size: 16px;
    font-family: "Europa-Bold";
    width: 100%;
    height: 40px;
    margin-top: 12px;
  }

  /*-----sign up css-------*/
  #sign-up h1 {
    font-size: 32px;
    line-height: 47px;
    color: #292f30;
    font-family: "Europa-Bold";
  }
  #sign-up .input-group {
    border: 0;
    background: transparent;
    padding: 11px 13px 11px 0;
  }
  #step2 {
    display: none;
  }

  #sign-up label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    font-family: "Europa-Bold";
  }
  #sign-up input {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-family: "FSElliotPro";
    background: #fff;
  }
  #sign-up select {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px;
    color: #000;
    margin-bottom: 11px;
    font-family: "FSElliotPro";
    background: #fff;
  }
  #sign-up input:focus {
    border: 1px solid #131c3785;
    outline: none;
  }
  #sign-up select:focus {
    border: 1px solid #131c3785;
    outline: none;
  }
  .fullwidthbtn {
    width: 100%;
    height: 48px;
    margin-top: 35px;
  }
  #sign-up select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../images/down-arrow.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 96% 50%;
    background-color: #fff;
  }

  form#regForm {
    margin: 0 auto 0 0;
    /* margin-top: 88px; */
    margin-bottom: 50px;
    padding-top: 88px;
    background: transparent;
  }
  .registerwrap {
    width: 55%;
  }
  .form-check-label {
    padding-left: 20px;
  }
  .custom-control-label a {
    color: #378f88;
    text-decoration: underline;
  }
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    width: 25px;
    height: 25px;
  }
  #sign-up .custom-control label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    font-family: "Europa-Bold";
    padding-left: 15px;
    padding-top: 0px;
    line-height: 29px;
  }

  .custom-control-label::after {
    position: absolute;
    top: 2px;
    left: -23px;
    display: block;
    width: 24px;
    height: 23px;
    content: "";
    background: no-repeat 50%/50% 50%;
    background-size: 15px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #378f88;
    background-color: #378f88;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 25px;
  }
  #sign-up #step2 input {
    margin-bottom: 11px;
  }
  #sign-up p {
    margin: 0;
    font-size: 15px;
    line-height: 29px;
    color: #a9a1a1;
    letter-spacing: 0.36px;
    font-family: "FSElliotPro";
  }

  aside {
    position: fixed;
    right: 0;
    width: 450px;
    top: 0;
    background: #eff2f5;
    height: 100vh;
  }
  .sideimg img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
    object-fit: cover;
  }
  .top-flex {
    display: flex;
  }
  .sidetext {
    padding-left: 10px;
  }
  aside h3 {
    font-size: 21px;
    font-family: "Europa-Bold";
    margin: 0;
    color: #fff;
  }
  .qomas2 {
    margin-left: 13px;
    width: 29px !important;
    height: 25px !important;
    /* padding-left: 14px; */
    top: 5px;
    position: relative;
  }
  aside h5 {
    font-size: 20px;
    margin-top: 17px;
    color: #fff;
  }
  .top-sidebar {
    padding: 41px 65px 92px 65px;
    background-image: url(../images/asidebg.png);
    background-size: cover;
    position: relative;
    height: 80%;
  }
  .top-sidebar:before {
    content: "";
    background: #0000;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .bottom-padd {
    padding: 5px 65px 0px 65px;
  }
  .bottom-sidebar {
    height: 20%;
  }
  .centerdiv {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
  }

  .bottom-sidebar {
    background: #378f88;
    position: relative;
  }

  #sign-up .bottom-sidebar p {
    margin: 0;
    font-size: 15px;
    line-height: 23px;
    color: #fffcfc;
    letter-spacing: 0.36px;
    font-family: "FSElliotPro";
  }
  #sign-up .bottom-sidebar ul {
    padding: 0;
    list-style: none;
    margin-top: 21px;
    margin-bottom: 0;
  }
  #sign-up .bottom-sidebar ul li {
    margin-bottom: 18px;
  }
  .bottom-sidebar h2 {
    font-size: 23px;
    color: #fff;
    font-family: "Europa-Bold";
    margin-bottom: 0px;
  }

  #sign-up {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #fff9ec),
      color-stop(90%, #fff)
    );
    background-image: -webkit-linear-gradient(top, #fff9ec 0, #fff 90%);
    background-image: -o-linear-gradient(top, #fff9ec 0, #fff 90%);
    background-image: linear-gradient(to bottom, #fff9ec 0, #fff 90%);
  }
  .nopaddright {
    padding-right: 00px;
  }
  .nopaddleft {
    padding-left: 00px;
  }

  aside .owl-dot span,
  aside .slick-dots li {
    width: 28px;
    height: 1px;
    border-radius: 0;
    display: inline-block;
    border: 0px;
    margin-right: 15px;
    opacity: 1 !important;
    background: #fff;
  }
  aside .owl-dot.active span {
    width: 28px;
    height: 1px;
    border-radius: 0;
    display: inline-block;
    border: 0px;
    margin-right: 15px;
    opacity: 1 !important;
    background: #378f88;
  }

  aside .owl-dots,
  aside .slick-dots {
    display: block !important;
    margin-top: 8px;
    margin: 0 auto;
    text-align: start;
  }

  .slick-dots li button:before {
    content: "" !important;
    display: none !important;
  }

  aside .social-media a {
    font-size: 17px;
    color: #fff;
    padding-right: 15px;
  }

  .waves {
    position: absolute;
    top: -73px;
    height: 11vh;
    margin-bottom: 0;
    min-height: 74px;
    max-height: 71px;
    width: 100%;
  }

  .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
  .sign-up-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
    width: 100%;
    padding-top: 29px;
    margin: 0 auto;
  }
  .colortextlink {
    color: #378f88;
    text-decoration: underline;
  }
  .colortextlink:hover {
    color: #378f88;
    text-decoration: underline;
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }

  .flex-btn {
    display: flex;
    justify-content: space-between;
  }
  .flex-btn .fullwidthbtn {
    width: 49%;
    height: 48px;
    margin-top: 35px;
  }
  .flex-btn .themebtnblack {
    width: 49%;
    height: 43px;
    line-height: normal;
    background: transparent;
    color: #292f30;
    border: 1px solid #292f30;
    border-radius: 5px;
    font-size: 19px;
    margin-left: 0;
    transition: all 0.3s ease-out;
  }

  .loginwrap {
    width: 50%;
    margin: 0 auto;
  }
  form#loginform {
    box-shadow: 1px 2px 30px #b9b9b963;
    -webkit-box-shadow: 1px 2px 30px #b9b9b963;
    background: #fff;
    padding: 25px;
    width: 40%;
    margin: 0 auto;
    margin-top: 57px;
    border-radius: 10px;
  }
  #login label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    font-family: "Europa-Bold";
  }
  #login input {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-family: "FSElliotPro";
    background: #fff;
  }
  #login .input-group {
    border: 0;
    background: transparent;
    padding: 11px 13px 11px 0;
  }

  #login {
    padding-top: 0;
    position: relative;
    height: 100vh;
  }

  #login .fullwidthbtn {
    width: 100%;
    height: 48px;
    margin-top: 28px;
  }
  .img-fluid.registerlogo {
    width: 37%;
  }
  .forgor-password {
    text-align: right;
  }
  .reglink {
    text-align: center;
    padding-top: 24px;
  }
  #login h1 {
    font-size: 33px;
    line-height: 58px;
    color: #292f30;
    font-family: "Europa-Bold";
  }
  .sign-in-top {
    padding-top: 36px;
    text-align: center;
  }

  #inner-page-banner {
    padding-top: 140px;
    padding-bottom: 60px;
  }

  #inner-page-banner h1 {
    text-align: center;
  }
  #pricing-top .nav-tabs {
    border-bottom: 0;
    justify-content: center;
    position: relative;
    top: 1px;
  }
  .activetab {
    background: #fff !important;
    border: 0;
    outline: 0;
  }
  #login .img-fluid.registerlogo {
    width: 8%;
  }

  .invisible-checkboxes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }
  .checkbox-alias {
    background-color: transparent !important;
    display: inline-block !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
    position: relative !important;
    transition: all 250ms ease-out !important;
    cursor: pointer !important;
    margin-right: 10px;
    border: 1px solid #ccc;
    height: 232px !important;
  }

  .invisible-checkboxes input[type="checkbox"] {
    display: none !important;
    /*   margin-right: -20px;U
  position: relative;
  z-index: 2; */
  }
  .invisible-checkboxes li {
    width: 48%;
    position: relative;
  }
  .invisible-checkboxes input[type="checkbox"]:checked {
    border: 1px solid #ccc;
  }

  #step3 {
    display: none;
  }

  .checkbox-alias h3 {
    font-size: 20px;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
  }

  .invisible-checkboxes li img {
    width: 31px;
    margin: 0 auto;
    display: block;
    padding-bottom: 11px;
  }

  label.customeradiobtn {
    padding: 10px 8px;
    margin: 5px;
  }
  .invisible-checkboxes input[type="checkbox"]:checked + .checkbox-alias {
    border: 1px solid #378f88 !important;
  }
  .hidden-list input:checked + label {
    background-color: transparent;
  }

  .hidden-list input + label {
    font-family: "Europa-Regular" !important;
    margin-bottom: 0px !important;
    margin-top: 0;
  }
  .inline-flex {
    display: block;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0;
    margin-left: 4px;
    margin-bottom: 0px;
    position: relative;
    top: 2px;
    padding-left: 10px;
  }
  .hidden-list {
    display: none;
    position: absolute;
    bottom: 69px;
    width: 100%;
  }
  .radio-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #sign-up #step2 .radio-content input {
    margin-bottom: 0px !important;
  }
  #sign-up .radio-content input {
    width: 25px;
    height: 22px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    font-family: "FSElliotPro";
    background: #fff;
    text-align: left;
  }
  .hidden-list h3 {
    font-size: 15px;
    text-align: center;
    padding-top: 9px;
    font-family: "Europa-Bold";
    margin-bottom: 15px;
  }

  #sign-up .hidden-list select {
    width: 250px;
    height: 40px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    font-size: 15px;
    color: #000;
    margin-bottom: 11px;
    font-family: "FSElliotPro";
    background: #fff;
    /* margin: 0 auto; */
    margin-bottom: 0px;
  }

  #sign-up .hidden-list select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../images/down-arrow.png);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 94% 50%;
    background-color: #fff;
  }

  .inlineradio {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  #sign-up #step2 .radiocontainer input {
    margin-bottom: 0;
    width: 20px;
    height: 18px;
  }
  #sign-up .radiocontainer label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    font-family: "Europa-Bold";
    padding-left: 27px;
    padding-right: 9px;
    color: #292f30;
  }

  .topslider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    width: 100%;
  }
  .topslider.topslideactive {
    top: 83px !important;
    transition: 0.5s;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .tooltip {
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    padding: 5px;
    /* ... */
  }
  .tooltip-inner {
    max-width: 210px;
    padding: 5px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
    background: #000000;
    text-align: left;
  }

  .radiocontainer {
    display: flex;
  }
  .inline-flex h3 i {
    width: 20px;
  }
  .activecolor {
    color: #378f88;
  }

  .cd-switch {
    text-align: center;
  }

  .switchFilter {
    width: 0;
    display: inline-block;
    background-color: #378f88;
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
    transition: all 0.4s cubic-bezier(0, 0, 0.25, 1);
  }

  .hidden-list label {
    cursor: pointer !important;
    text-transform: uppercase !important;
    border: 0 !important;
    width: 124px !important;
    padding: 10px 0 !important;
    text-align: center !important;
    display: inline-block !important;
    margin-right: 0px !important;
    font-size: 13px;
    color: #000;
  }

  .switch {
    position: absolute;
    display: flex;
    border: 1px solid #378f88;
    border-radius: 39px;
    left: 50%;
    transform: translateX(-50%);
  }
  .switch input[type="radio"] {
    visibility: hidden;
    position: absolute;
    height: 100%;
  }

  #sign-up .switch label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    font-family: "Europa-Bold";
  }

  #sign-up .marketplacelist label {
    display: inline-block;
    margin-bottom: 18px;
    width: 100%;
    font-family: "Europa-Bold";
  }

  .invisible-checkboxes svg {
    overflow: hidden;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin-bottom: 0px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .invisible-checkboxes #grocerysvg {
    width: 19px;
  }

  .headingcenter {
    text-align: center;
  }
  .heading.headingcenter::after {
    left: 50%;
    transform: translateX(-50%);
  }

  #contactus .contact-para {
    padding: 16px 43px 16px 00px;
    text-align: left;
  }
  .infocontact {
    margin-bottom: 20px;
    padding-left: 25px;
  }
  .infocontact h3 {
    font-size: 18px;
    color: #fff;
  }
  .infocontact a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }

  header svg,
  header svg path {
    fill: #378f88 !important;
    stroke: #fff !important;
  }

  .text-center .heading::after {
    content: "";
    position: absolute;
    width: 66px;
    height: 3px;
    background: #378f88;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  .integrations-list {
    border: 1px solid #efefef;
    box-shadow: 0px 7px 15px #eeeeee80;
    display: flex;
    align-items: center;
    padding: 25px 26px;
    background: #fff;
  }
  .inte-text {
    padding-left: 19px;
  }

  #step2 a {
    color: #378f88;
    text-decoration: none;
    background-color: transparent;
    padding-left: 6px;
  }

  .invisible-checkboxes
    input[type="checkbox"]:checked
    + .checkbox-alias::after {
    right: -6px;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    color: #ffffff;
    font-size: 18px;
    padding-right: 0;
    position: absolute;
    top: -10px;
    font-size: 15px;
    background: #378f88;
    height: auto;
    line-height: inherit;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    /* position: relative; */
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 1em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cloud {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 50%;
    width: 4.6875em;
    height: 4.6875em;
    margin: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: -2.1875em 0.6875em 0 -0.6875em, 2.0625em 0.9375em 0 -0.9375em,
      0 0 0 0.375em #378f88, -2.1875em 0.6875em 0 -0.3125em #ff5a00,
      2.0625em 0.9375em 0 -0.5625em #ff5a00;
    background: transparent;
    transform: translate(-50%, -50%);
  }
  .cloud::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -0.5em;
    display: block;
    width: 4.5625em;
    height: 1em;
    background: #fff;
    box-shadow: 0 0.4375em 0 -0.0625em #378f88;
  }
  .cloud:nth-child(2) {
    z-index: 0;
    background: #378f88;
    box-shadow: -2.1875em 0.6875em 0 -0.6875em #378f88,
      2.0625em 0.9375em 0 -0.9375em #378f88, 0 0 0 0.375em #378f88,
      -2.1875em 0.6875em 0 -0.3125em #378f88,
      2.0625em 0.9375em 0 -0.5625em #378f88;
    opacity: 0.3;
    transform: scale(0.5) translate(6em, -3em);
    -webkit-animation: cloud 2s linear infinite;
    animation: cloud 2s linear infinite;
  }
  .cloud:nth-child(2):after {
    background: #378f88;
  }

  /* Animations */

  @-webkit-keyframes cloud {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
      transform: scale(0.5) translate(-200%, -3em);
    }
  }

  @keyframes cloud {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
      transform: scale(0.5) translate(-200%, -3em);
    }
  }

  .loader-img {
    width: 80px;
    margin: 0 auto;
    display: block;
  }
  .progress {
    margin-top: 12px;
    width: 249px;
  }
  .progress-bar-success {
    color: var(--primary-color3) !important;
    line-height: 22px;
  }

  .loadingtext {
    font-size: 18px;
    margin-top: 19px;
    margin-bottom: 0;
    text-align: center;
    color: #000;
    text-transform: capitalize;
  }

  .loading {
    --load: 10000ms;
    --border: #646b8c;
    --background: #fff;
    --background-perspective: #f6f8ff;
    --active: #5c86ff;
    margin: 28px 0;
  }
  .loading > div {
    transform: perspective(var(--p, 0)) rotateX(var(--rx, 0deg))
      rotateY(var(--ry, 0deg));
    transition: transform 0.5s;
    transform-style: preserve-3d;
    position: relative;
    cursor: pointer;
  }
  .loading > div span {
    display: block;
    color: var(--c, #404660);
    line-height: 23px;
    font-size: var(--s, 16px);
    font-weight: 500;
    margin-bottom: 8px;
  }
  .loading > div span:nth-child(2) {
    --c: #bbc1e1;
    --s: 14px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
  }
  .loading > div ul {
    width: 360px;
    height: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .loading > div ul li {
    --ry: 0deg;
    --z: 0;
    --x: 0;
    position: absolute;
    bottom: 0;
    background: #eeeeee91;
    width: var(--w, 200px);
    left: var(--l, 0);
    height: 19px;
    transform: rotateY(var(--ry)) translateZ(var(--z)) translateX(var(--x));
    transform-origin: 0 0;
  }
  .loading > div ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-color3) !important;
    transform-origin: var(--o, 0) 0;
    transform: scaleX(0);
    -webkit-animation: fill var(--duration) linear forwards var(--delay);
    animation: fill var(--duration) linear forwards var(--delay);
  }
  .loading > div ul li:nth-child(1) {
    --delay: 0ms;
    --duration: calc(var(--load) * calc(1200 / 960));
  }
  .loading > div ul li:nth-child(2) {
    --delay: calc(var(--load) * calc(1200 / 960));
    --duration: calc(var(--load) * calc(100 / 960));
    --w: 100px;
    --l: 200px;
    --ry: 90deg;
    --b: var(--background-perspective);
  }
  .loading > div ul li:nth-child(3) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960))
    );
    --duration: calc(var(--load) * calc(32 / 960));
    --w: 32px;
    --l: 200px;
    --z: -100px;
  }
  .loading > div ul li:nth-child(4) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960))
    );
    --duration: calc(var(--load) * calc(100 / 960));
    --w: 100px;
    --l: 232px;
    --ry: 90deg;
    --o: 100%;
    --b: var(--background-perspective);
  }
  .loading > div ul li:nth-child(5) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960))
    );
    --duration: calc(var(--load) * calc(20 / 960));
    --w: 20px;
    --l: 232px;
  }
  .loading > div ul li:nth-child(6) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960))
    );
    --duration: calc(var(--load) * calc(140 / 960));
    --w: 140px;
    --l: 252px;
    --ry: 90deg;
    --b: var(--background-perspective);
  }
  .loading > div ul li:nth-child(7) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960)) + calc(var(--load) * calc(140 / 960))
    );
    --duration: calc(var(--load) * calc(24 / 960));
    --w: 24px;
    --l: 252px;
    --z: -140px;
  }
  .loading > div ul li:nth-child(8) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960)) + calc(var(--load) * calc(140 / 960)) +
        calc(var(--load) * calc(24 / 960))
    );
    --duration: calc(var(--load) * calc(200 / 960));
    --w: 200px;
    --l: 276px;
    --ry: 90deg;
    --x: -60px;
    --o: 100%;
    --b: var(--background-perspective);
  }
  .loading > div ul li:nth-child(9) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960)) + calc(var(--load) * calc(140 / 960)) +
        calc(var(--load) * calc(24 / 960)) + calc(var(--load) * calc(200 / 960))
    );
    --duration: calc(var(--load) * calc(48 / 960));
    --w: 48px;
    --l: 276px;
    --z: 60px;
  }
  .loading > div ul li:nth-child(10) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960)) + calc(var(--load) * calc(140 / 960)) +
        calc(var(--load) * calc(24 / 960)) + calc(var(--load) * calc(200 / 960)) +
        calc(var(--load) * calc(48 / 960))
    );
    --duration: calc(var(--load) * calc(60 / 960));
    --w: 60px;
    --l: 324px;
    --ry: 90deg;
    --x: -60px;
    --b: var(--background-perspective);
  }
  .loading > div ul li:nth-child(11) {
    --delay: calc(
      calc(var(--load) * calc(1200 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(32 / 960)) + calc(var(--load) * calc(100 / 960)) +
        calc(var(--load) * calc(20 / 960)) + calc(var(--load) * calc(140 / 960)) +
        calc(var(--load) * calc(24 / 960)) + calc(var(--load) * calc(200 / 960)) +
        calc(var(--load) * calc(48 / 960)) + calc(var(--load) * calc(60 / 960))
    );
    --duration: calc(var(--load) * calc(36 / 960));
    --w: 36px;
    --l: 324px;
  }
  .loading input {
    display: none;
  }
  .loading input:checked + div {
    --rx: -16deg;
    --ry: -24deg;
    --p: 600px;
  }

  @-webkit-keyframes fill {
    to {
      transform: scaleX(1);
    }
  }

  @keyframes fill {
    to {
      transform: scaleX(1);
    }
  }

  .loading > div ul li:nth-child(11)::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #eeeeee91;
    transform-origin: var(--o, 0) 0;
    transform: scaleX(0);
    -webkit-animation: fill var(--duration) linear forwards var(--delay);
    animation: fill var(--duration) linear forwards var(--delay);
  }

  .switch .active {
    position: relative;

    .switchFilter {
      width: 100%;
      position: absolute;
      opacity: 1;
      border-radius: 17px;
      border-bottom-right-radius: 17px;
    }
    .auth-body #sign-up .switch label {
      color: #fff !important;
    }
  }

  .active label {
    color: #fff !important;
  }
}
div#sidebar-menu li.mm-active > a {
  // background: var(--gradient-bg) !important;
}
.themebtn {
  background: var(--primary-color) !important;
  color: var(--primary-color3) !important;
}

.themebtn:hover {
  background: var(--primary-color3) !important;
  color: var(--primary-color) !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus {
  color: var(--primary-color) !important;
}
.auth-body .colortextlink {
  color: var(--primary-color) !important;
}
.spinner-border.text-primary {
  color: var(--primary-color) !important;
}
img#logo_header.logo-dark {
  filter: invert(0);
}
.logo-data {
  width: 211px;
  height: 62%;
  padding: 1px 36px 3px 42px;
}
.buttoncolor {
  // background: var(--gradient-bg) !important;
  color: var(--black-color);
  border-color: var(--primary-color);
}

.buttoncolor:hover {
  background: var(--gradient-bg) !important;
  color: var(--gradient-bg) !important;
  border-color: var(--primary-color3);
}
.vertical-collpsed .logo span.logo-sm {
  padding-left: 6px;
}
.img.img-fluid.registerlogo {
  max-width: 100% !important;
  height: 80px !important;
  width: 80px !important;
}

.navbar-header {
  background: #378f88;
}





// new css
.auth-body #login {
  background: #101010;
}
.auth-body form#loginform {
  width: 60%;
  margin: 0px;
  border-radius: unset;
}

.form_Side {
  display: flex;
  width: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}
.login_right_banner {
  width: 63%;
  background: #101010;
}
.login_right_banner img {
  width: 100%;
  height: 100%;
}
.auth-body form#loginform .sign-in-top img {
  width: 100%;
}
.auth-body form#loginform .sign-in-top {
  width: 50%;
  margin: 0px auto;
}
.login_heading {
  padding-left: 40px;
  margin-top: 8px;
}
.login_heading h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #252733;
  margin: 0;
} 
.login_heading p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #9FA2B4;
  padding: 10px 0;
}
.auth-body #login label{
  font-weight: 600;
font-size: 12px;
line-height: 15px;
text-transform: uppercase;
color: #101010;
}

.auth-body #login input {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border: 1px solid #101010;
  border-radius: 8px;
}

.auth-body #login input::placeholder{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #9FA2B4;
}

.auth-body #login .fullwidthbtn{
  background-color: #003F89 !important;
}

.auth-body .colortextlink{
  color:#003F89 !important;
  font-size: 14px;
}
.auth-body .reglink p {
  letter-spacing: 0.2px;
  color: #9E9E9E;
  font-size: 14px;
}

.auth-body .forgot_password label{
  text-transform: capitalize !important;
}
input#flexCheckDefault {
  height: 18px !important;
  left: 0px;
  top: 5px;
  margin: 0 !important;
  width: 18px !important;
}
.forgot_check {
  display: flex;
  align-items: center;
}
.forgot_check label, .forgot_check a{
  width: auto !important;
  margin: 0 !important;
  padding-left: 10px !important;
  letter-spacing: 0.2px;
color: #3E4958 !important;
font-size: 14px;
font-weight: normal !important;
}
.forgot_check a{
  text-decoration: underline !important;
}
.vertical-menu {
  background: #101010 !important;
}



.vertical-menu{
  top: 0px !important;
}
.dashlogo_box {
  padding: 14px 30px 0 30px;
}
.dashlogo_box h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.6);
  margin: 26px 0;
}
.metismenu {
  padding-left: 30px !important;
}
div#sidebar-menu li.mm-active{
  background: #fff;
}
.svg_icon {
  height: 28px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content {
  overflow-y: scroll !important;
  background: #101010 !important;
  height: 100vh;
}
.main-content .page-content {
  background: #fff;
  margin: 26px 0;
  border-radius: 30px;
  width: 98%;
  padding: 30px 15px;
  min-height: 100vh;
}
.trsaction_content h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #2E3A59;
  margin: 0;
}
.trsaction_content p {
  margin: 0;
}


.welcome_msg_dash h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  color: #2E3A59;
  margin: 0;
}
.welcome_msg_dash {
  display: flex;
  align-items: center;
  gap: 20px;
}
.welcome_msg_dash p {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2E3A59;
}
.welcome_msg_dash p a{
  color: #3366FF;
}
.user_img {
  height: 46px;
  width: 46px;
  border-radius: 100px;
  border: 2px solid #DFE0EB;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 8px;
}
.user_img img{  
  width: 100%;
  height: 100%;
  object-fit: cover;  
}
.dash_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_profile {
  display: flex;
  align-items: center;
} 
.user_content h4 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}
.user_content p {
  margin: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.6;
}
.header_userside {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userlogout {
  padding-left: 30px;
}
.common_card {
  border-radius: 11px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.welcome_card {
  background: #3c89005e;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  padding: 15px 15px 15px 59px;
}
.booking_card {
  background: #FFD2D7;
}
.revenue_card {
  background: #fff0d6;
}
.customer_card {
  background: #DDF0ff;
}
section.Welcomecards_dashboard {
  padding: 60px 0 0px 100px;
}
.charatcter_img {
  position: absolute;
  left: -88px;
    top: -43px;
}
.welcome_card h3 {
  font-weight: 800;
  font-size: 18px;
  line-height: 32px;
  color: #2E3A59;
  margin: 0;
}
.welcome_card p {
  margin: 0;
  font-weight: 400;
font-size: 15px;
line-height: 26px;
color: #2E3A59;
}
.charatcter_img {
  height: 140px;
  width: 130px;
}
.charatcter_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.inner_content p {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #2E3A59;
}
.inner_content h4 {
  margin: 0;
  font-weight: 700;
}
.inner_img {
  height: 62px;
  width: 62px;
}
.inner_img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
h2.fillter_head {
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #2E3A59;
  margin: 0;
}
.fillter_bar {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
input#search-bar-0 {
  background: #FFFFFF;
  border: 1px solid #F2ECFF;
  box-shadow: 0px 12px 24px rgba(0, 72, 217, 0.05);
  border-radius: 30px !important;
  padding: 22px 20px;
}
input#search-bar-0::placeholder {
  font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
color: #9DA3BB;
}
.search-box.inline-button .search-icon-btn {
  top: 10.49px !important;
}
button.btn-success {
  background: #24C18F;
  border-radius: 6px;
  border: none;
  width: 194px;
  height: 46px;
  font-size: 15px;
}
button.btn-success:hover
{
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}
.search_icon {
  position: absolute;
  right: 15px;
  top: 13px;
}
.seacrch_ingput div, .fillter_dropdown div {
  margin: 0 !important;
}
.fillter_dropdown {
  width: 26%;
}
.fillter_dropdown select {
  background: #FFFFFF;
  border: 1px solid #F2ECFF;
  box-shadow: 0px 12px 24px rgba(0, 72, 217, 0.05);
  border-radius: 5px;
  height: 44px;
  padding-left: 20px;
}
.recent_trasaction {
  background: #FFF2F4;
  padding: 20px 20px 20px 38px;
}
.recent_trasaction h4 {
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  color: #2E3A59;
  margin: 0;
}
ul.trsaction_list li {
  list-style-type: none;
}
ul.trsaction_list li:not(:last-child) {
  margin-bottom: 18px;
}
ul.trsaction_list {
  padding: 0;
  margin: 0;
  margin-top: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  height: 100%;
  min-height: 304px;
  max-height: 304px;
}
ul.trsaction_list::-webkit-scrollbar {
  width: 8px;
}
ul.trsaction_list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
ul.trsaction_list::-webkit-scrollbar-thumb {
  background: #FFD4DB;
  border-radius: 100px;
}
ul.trsaction_list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.imgforblack, .imgforblue, .imgforyellow {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.imgforblack {
  border: 1px solid #000;
}
.imgforblue{
  border: 1px solid #3895E2;
}
.imgforyellow{
  border: 1px solid #F2B96F;
}
.imgforblue svg path{
  fill: #3895E2;
}
.imgforyellow svg path{
  fill: #F2B96F;
}
.trasaction_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trasaction_wrap {
  display: flex;
  align-items: center;
}
h5.trasaction_price {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #2E3A59;
}
.latets_user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.latets_user p {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #192A3E;
}
.user_profile_table {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 11px;
}
.user_profile_table img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}
td.phone_number {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #90A0B7;
}
.emailaddress p, .timedate p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #192A3E;
  margin: 0;
}
.table thead tr th {
  font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #505470;
// text-align: center;
}
.table tbody tr td {
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #192A3E;
  margin: 0;
  // text-align: center;
}
.table tbody tr td .form-check, .table thead tr th .form-check{
  top: -17px;
}
.table thead tr th{
  background: #F7F8FA !important;
}
.status_active span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #24C18F;
  width: fit-content;
    padding: 2px 13px 2px 13px;
}
.status_active {
  background: #24c18f3b;
  border-radius: 100px;
  text-align: center;
  width: fit-content;
    padding: 2px 13px 2px 13px;
}
.status_inactive{
  background:  rgba(193, 36, 36, 0.1);;
  border-radius: 100px;
  text-align: center;
  padding: 2px 0;
  width: fit-content;
  padding: 2px 13px 2px 13px;
}

.status_inactive span{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #C12424;  
  width: fit-content;
  padding: 2px 13px 2px 13px;
}
button.button-eye {
  background: transparent;
  border: none;
  padding: 0;
}
button.button-eye:hover{
  background: transparent !important;
}
.latetsttable_here .table thead tr th:first-child {
  text-align: left;
} 

.latetsttable_here {
  height: 100%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}
.latetsttable_here::-webkit-scrollbar {
  width: 8px;
}
.latetsttable_here::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.latetsttable_here::-webkit-scrollbar-thumb {
  background: #FFD4DB;
  border-radius: 100px;
}
.latetsttable_here::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.slot_button {
  display: flex;
  justify-content: flex-end;
}
button.add_slot {
  background: #24C18F;
  border-radius: 6px;
  border: none;
  width: 194px;
  height: 46px;
  font-size: 15px;
}
.newtable_list {
  max-height: 500px;
  min-height: 500px;
}
ul.Booking_statuslist {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
ul.Booking_statuslist li{
  list-style-type: none;
  padding-right: 50px;
}
.Booking_status {
  display: flex;
}
.icon_svg {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.hourly_stauts {
  background: #CDFFE1;
}
.Daily_status {
  background: #FDEFEE;
}
.Monthly_status {
  background: #EEF0FD;
}
.Available_status {
  border: 1px solid #000;
  border-style: dashed;
}
.bookingSlot_left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.Booking_top_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F3FBFF;
  padding: 20px 10px;
}

ul.fslots_main{
  padding: 0;
  margin: 0;
  display: flex;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
}

ul.fslots_main li{
  list-style-type: none;
}

.f1_available, .f2_daily, .f2_monthly, .f2_hourly{
  width: 114px;
  height: 130px;
}
.f1_available {
  border: 1px solid #000;
  border-style: dashed;
}
.f2_daily{
  background: #FDEFEE;
}
.f2_monthly{
  background: #EEF0FD;
}
.f2_hourly{
  background: #CDFFE1;
}
button.btnthree_dots {
  background: transparent;
  padding: 0;
  border: none;
}
button.btnthree_dots:hover, button.btnthree_dots.active, button.btnthree_dots:focus{
  background: transparent !important;
}
.boxslot_common {
  padding: 8px;
  text-align: center;
}
.boxslot_common p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
}
button.btnthree_dots {
  position: relative;
  right: -40px;
}
ul.fslots_main li:nth-child(5), ul.fslots_main li:nth-child(12), ul.fslots_main li:nth-child(19)  {
  margin-left: 90px;
}

.Selectpricemod_modal .modal-dialog {
  max-width: 610px !important;
}
.Selectpricemod_modal .modal-content{
  border-radius: 20px;
  border: none;
}
.Selectpricemod_modal .modal-body {
  padding: 30px 82px;
}
.modal_content{
  text-align: center;
}
.modal_content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: #101010;
}
.modal_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #101010;
}
form.Selectpricemod_modal_form .form-group {
  text-align: left;
}
form.Selectpricemod_modal_form .form-group label{
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #101010;
  padding-left: 20px;
}
form.Selectpricemod_modal_form .form-group input {
  background: #FCFDFE;
  border: 1px solid #101010;
  border-radius: 8px;
  padding: 22px;
}
form.Selectpricemod_modal_form .form-group input:focus{
  box-shadow: unset;
}
button.btn-save {
  background: #24C18F;
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 51px;
  font-weight: 400;
font-size: 15px;
line-height: 32px;
color: #FFFFFF;
}
section.Censorbottom_bottom {
  margin-top: 60px;
  padding-left: 60px;
}
.censor_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.censor_btns button {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  gap: 10px;
  align-items: center;
}
.censor_btns button:hover, .censor_btns button:focus, .censor_btns button.active{
   background: transparent !important;
}
.censor_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
}
button.btn_add {
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #000000 !important;
}
button.btn_remove.btn {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #FF0000 !important;
}
section.add_cesormain{
  margin-top: 60px;
}
a.backto_censor {
  display: inline-flex;
  align-items: center;
  margin-bottom: 34px;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #2E3A59;
  gap: 10px;
}
form.add_form label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #000000;
}
form.add_form input{
  background: #FCFDFE;
border: 1px solid #101010;
border-radius: 8px;
padding: 20px 15px;
}
form.add_form input::placeholder{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #9FA2B4;
}
form.add_form input:focus{
  box-shadow: unset;
}
button.btn_addform {
  background: #24C18F;
  border-radius: 6px;
  border: none;
  width: 150px;
  height: 43px;
  font-weight: 500;
font-size: 20px;
line-height: 32px;
color: #FFFFFF;
}

section.Subscriptionplan_main {
  margin-top: 60px;
}
.standard_Planside {
  background: #EEF0FD;
  padding: 20px;
}
.standard_Planside p{
  margin: 0;
  font-style: normal;
font-size: 15px;
line-height: 32px;
color: #000000;
}
.upgrade_Card {
  background: #F4FCED;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
    padding: 36px 50px;
}
.upgrde_content h3 {
  margin: 0;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #2E3A59;
}
.upgrde_content h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #549918;
  margin: 0;
  margin-top: 10px;
}
.upgrde_content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #4F4F4F;
  margin: 0;
}
button.Upgrade_btn.btn {
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #FFFFFF;
  background: #49A500;
  border-radius: 42px;
  border: blanchedalmond;
  width: 120px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Card_info {
  background: #F4FCED;
  border-radius: 20px;
  margin-top: 40px;
  padding: 20px 50px;
  display: flex;
    justify-content: space-between;
}
.card-Name {
  display: flex;
  justify-content: flex-start;
  gap: 17px;
  margin-top: 20px;
}
.card-Name p:first-child {
  width: 120px;
}
.Card_info .upgrde_content h3 {
  margin-bottom: 19px;
}
.editt-wrap {
  text-align: center;
  width: 25%;
  display: grid;
}
.editt-wrap button.Upgrade_btn {
  margin: 101px auto 0;
}
section.Editprofile_Main {
  padding-top: 60px;
  padding-left: 88px;
}
.phonenumber {
  display: flex;
  gap: 21px;
  justify-content: flex-start;
}
.phonenumber .form-group:first-child {
  width: 13%;
}
.phonenumber .form-group:last-child {
  width: 100%;
}




.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.button_uploads 
 .upload-btn-wrapper .btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.button_uploads 
 .upload-btn-wrapper .btn , button.remove {
    background: #4D9C40;
    font-weight: 500;
    font-size: 11px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    border: none;
    width: 130px;
    text-align: center;
    justify-content: center;
}
button.remove{
  background: #C12424;
}
.upload_img {
  width: 152px;
  height: 152px;
  border-radius: 100px;
  overflow: hidden;
}
.upload_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wraaping_both {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}
.auth-body form#regForm {
  width: 60%;
  padding: 20px;
  margin: 0 !important;
}
.custom-checkbox {
  margin: 0 !important;
}
.auth-body .custom-checkbox .custom-control-label::before {
  width: 18px;
  height: 18px;
}
.auth-body .custom-control-label::after {
  width: 17px;
  height: 23px;
}
.terms_page_content h1 , .terms_page_content h3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 50px;
}
.terms_page_content h3 {
  margin-bottom: 20px !important;
  font-size: 30px;
}
.terms_page_content ul{
  padding-left: 17px;
}
.terms_page_content ul li {
  list-style-type: auto;
}
.terms_page_content h6 {
  color: #000;
  font-size: 18px;
}
form.contact_form {
  background: #E9F9F4;
  padding: 30px;
}
section.contact_us {
  padding-left: 60px;
}
.contact_us_head h2 {
  font-weight: 600;
  font-size: 35px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 20px;
}
.contact_us_head p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.contactform_here {
  margin-top: 30px;
}
form.contact_form label {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #101010;
}
form.contact_form textarea{
  height: 80px;
}
form.contact_form input, form.contact_form textarea {
  background: #FCFDFE;
border: 1px solid #101010;
border-radius: 8px;
}
form.contact_form input:focus, form.contact_form textarea:focus{
  box-shadow: unset;
}
form.contact_form input::placeholder{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #9FA2B4;
}


.addslot_modalfrom .modal-content {
  border: none;
  border-radius: 10px;
}
.form_header h3 {
  font-weight: 800;
  font-size: 30px;
  line-height: 28px;
  color: #2E3A59;
  margin: 0;
}
.form_header{
  display: flex;
  justify-content: center;
  align-items: center;
}
a.close_btn {
  position: absolute;
  right: 0;
}
.addslot_modalfrom .modal-body {
  padding: 60px 80px;
}
.form_layout h5 {
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #2E3A59;
}
.form_layout label {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #101010;
}
.form_layout input, .location_div{
  background: #FCFDFE;
border: 1px solid #101010;
border-radius: 8px;
}
.form_layout input:focus{
  box-shadow: unset;
}
.form_layout input::placeholder{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #9FA2B4;
}
.location_div{
  padding: 20px;
  display: flex;
  align-items: center;
}
.location_address p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9FA2B4;
  margin: 0;
}
.location_address {
  padding-left: 50px;
}
.addslot_modalfrom .modal-dialog {
  max-width: 900px;
}


.common_profile_card {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 14px 10px;
}
.profile_pic {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 15px;
  background-color: #cccccc61;
}
.profile_pic img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}
.profile_picname h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #192A3E;
  margin: 0;
}
.profile_picname {
  display: flex;
  align-items: center;
}
.profile_status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 20px;
}
.status_profile h4, .status_profile p {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #192A3E;
  margin: 0;
}
.status_profile h4{
  font-size: 18px !important;
}
.divider_line {
  height: 2px;
  background: #cccccc85;
  margin: 17px 0px;
}
button.send_msg {
  background: #24C18F;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
ul.personal_info_list li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
ul.personal_info_list li p strong, ul.personal_info_list li p{
  margin: 0;
  font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #192A3E;
}
ul.personal_info_list li p{
  color: #90A0B7 !important;
}
ul.personal_info_list {
  padding: 0px;
  margin: 0px;
}
ul.personal_info_list li:not(:last-child){
  margin-bottom: 20px;
}
.head_withbutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.common_profile_card h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #192A3E;
  margin: 0;
}
button.edit_btn {
  line-height: 13px;
}
.address_Card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #90A0B7;
  margin: 0;
  margin-top: 10px;
}
.table_confirmed {
  background: #F8F0D4;
  text-align: center;
}
.table_confirmed p{
  color:#988903;
  margin: 0;
}
.table_Cancelled{
  background: #F8D4D4;
  text-align: center;
}
.table_Cancelled p{
  color: #FF0909;
  margin: 0;
}
.userdetails_list {
  max-height: 525px;
  min-height: 525px;
}
// new css


.card_coommnsub {
  padding: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.card_coommnsub ul li{
  text-align: left;
  font-weight: 400;
font-size: 20px;
line-height: 26px;
color: #2E3A59;
}
.card_coommnsub ul li:not(:last-child){
  margin-bottom: 20px;
}
.monthly_Card{
  background: #FEF0F0;
}
.threemonthly_Card {
  background: #E4FFE7;
}
.sixmonthly_Card{
  background: #FAF1FE;
}
.card_coommnsub h5{
  font-weight: 800;
font-size: 20px;
line-height: 28px;
color: #2E3A59;
}
.card_coommnsub h1{
  font-weight: 400;
font-size: 60px;
line-height: 28px;
color: #2E3A59;
margin: 40px 0; 
}

button.btn_card_comon {
  width: 194px;
  height: 51px;
  margin-top: 20px;
  border-radius: 6px;
  font-weight: 300;
font-size: 22px;
line-height: 32px;
border: none;
}
button.month_red{
  background: #FF0000;  
}
button.threemonth_btn {
  background: #11A721;
}
button.sixmoth_btn {
  background: #B100FF;
}
.btn_align {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.toast-error {
    background-color: #BD362F !important;
}
.cursor-pointer{
  cursor: pointer !important;
}

.metismenu{
  overflow-y: scroll;
  height: 500px !important;
 
}

 /* width */
 .metismenu::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.metismenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px; 
  border-radius: 2px;
}

.metismenu::-webkit-scrollbar-thumb {
  background: lightgreen; 
  border-radius: 2px;
}

/* Handle on hover */
.metismenu::-webkit-scrollbar-thumb:hover {
  background: lightgreen; 
}

.subscription-plans-table-wrap table, .subscription-plans-table-wrap th, .subscription-plans-table-wrap td{
  background-color: #f7f7f7;
  text-align: center;
}
.subscription-plans-table-wrap table{
  border: 2px solid #e5e5e5 !important;
}

.chart-card-bg{
  background-color: #eff8ff !important;
  border: 0 !important;
}

.common-heading{
  font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: #2E3A59;
    margin: 0;
}

.common-btn{
    background: #24C18F !important;
    border-radius: 6px !important;
    border: none !important;
    padding: 10px !important;
    height: 46px !important;
    font-size: 15px !important;
}
.react-time-picker__inputGroup input {
  height: 7px;
  padding: 14px !important;
  border: none !important;
  background: unset !important;
}
.react-time-picker__inputGroup select {
  background: unset !important;
}
.react-time-picker__inputGroup input:focus, .react-time-picker__inputGroup select:focus{
  outline: none;
  background: unset !important;
}