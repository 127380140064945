//
// Form Wizard
//

// twitter-bs-wizard

.twitter-bs-wizard {
  .twitter-bs-wizard-nav {
    padding: 4px;
    background-color: rgba($primary, 0.1);

    .step-number {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 34px;
      border: 2px solid $primary;
      color: $primary;
      text-align: center;
      border-radius: 50%;

      @media (max-width: 991.98px) {
        display: block;
        margin: 0 auto 8px !important;
      }
    }

    .nav-link {
      @media (min-width: 992px) {
        text-align: left;
      }
      &.active {
        background-color: transparent;
        color: $gray-700;

        .step-number {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  .twitter-bs-wizard-pager-link {
    padding-top: 24px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    li {
      display: inline-block;
      a {
        display: inline-block;
        padding: 0.47rem 0.75rem;
        background-color: $primary;
        color: $white;
        border-radius: 0.25rem;
      }
      &.disabled {
        a {
          cursor: not-allowed;
          background-color: lighten($primary, 8%);
        }
      }

      &.next {
        float: right;
      }
    }
  }
}

.twitter-bs-wizard-tab-content {
  padding-top: 24px;
  min-height: 262px;
}
