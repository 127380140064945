$primary: #378f88;

body {
  font-weight: 500;
}
.get-started.active.card-header i {
  color: #378f88 !important;
}
.header-title {
  display: flex;
  justify-content: space-between;
}
h4.h4-is-center {
  text-align: center;
  width: 100%;
}
small,
.small {
  font-size: 90%;
}
i.mdi.permission.mdi-check-circle {
  color: $primary;
}
i.mdi.permission.mdi-checkbox-blank-circle {
  color: #808080fa;
}
.domain-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
}
.domain-item button {
  width: 8%;
}

.domains-list.row div.card {
  border: solid 1px gray;
  box-shadow: 0 0 3px 0px;
}

#sidebar-menu ul li a {
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff;
}
#sidebar-menu ul li a:hover{
  color: #3cc390;
}

#sidebar-menu ul li a svg{
  margin-right: 10px;
}

table.react-bootstrap-table a.text-body {
  color: $primary !important;
}

select.custom-select {
  width: 100px;
}
label.search-label input {
  border-radius: 5px !important;
}

button.btn-rounded.search-icon-btn {
  background: unset;
  color: #8b909b;
  border: none;
}
.search-box.inline-button .search-icon-btn {
  top: 2.49px;
}

.btn-rounded {
  border-radius: 5px;
}

.pagination.pagination-rounded .page-link {
  border-radius: 5px !important;
}
a.text-body.font-weight-bold span {
  color: $primary;
}
a.text-secondary i,
a.text-danger i,
a.text-primary i {
  background: #f8f8fb;
  padding: 5px 5px;
  border-radius: 3px;
}
i.fas.fa-dollar-sign {
  padding: 5px 7px;
}
a.text-secondary i:hover,
a.text-danger i:hover,
a.text-primary i:hover {
  background: $primary;
  color: white;
}

.mm-active,
.mm-active i {
  color: #535a6d !important;
}
div#sidebar-menu li.mm-active {
  color: #101010 !important;
  background: #fff;
  border-radius: 8px;
}

div#sidebar-menu li.mm-active a svg path{
  stroke:#3E3A3A;
}

div#sidebar-menu li.mm-active ul {
  background: white !important;
}
div#sidebar-menu li.mm-active ul li.mm-active,
div#sidebar-menu li.mm-active ul li.mm-active a {
  background: white !important;
  color: $primary !important;
}

div#sidebar-menu li.mm-active > a,
div#sidebar-menu li.mm-active > a > i {
  color: #101010 !important;
}

.search-box button.btn-sm.btn-rounded.search-icon-btn:active {
  background-color: white;
  border: none;
  color: $primary;
}

button.btn-sm.btn-rounded.search-icon-btn.btn.btn-primary:active {
  border: 0 white;
  background: white;
}
button.btn-sm.btn-rounded.search-icon-btn.btn.btn-primary:hover i {
  color: $primary;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.welcome-board.row {
  background: white;
  border-radius: 5px;
  border: solid 1px #3cc390;
  padding: 1px;
}

.label-content {
  b {
    margin-right: 5px;
  }
}

table.map-view-drivers {
  thead {
    display: none;
  }
}
/* // common box */
.commonBox {
  width: 100%;
  border: 1px solid #80808047;
  box-shadow: -1px 2px 4px -2px;
  width: 100%;
  padding: 2px;
  margin: 10px;
}

#logo_header {
  width: 120px;
  height: 50px;
  padding: 1px 36px 3px 42px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dropbtn {
  background-color: orange;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropText {
  display: block;
  position: absolute;
  background-color: skyblue;
  min-width: 120px;
  z-index: 1;
}
.dropText a {
  color: black;
  padding: 12px 25px;
  text-decoration: none;
  display: block;
}
.dropText a:hover {
  background-color: blue;
  color: white;
}
.dropdown:hover .dropText {
  display: block;
}
.dropdown:hover .dropbtn {
  background-color: yellow;
}

.loader_bg {
  color: #378f88;
  backdrop-filter: blur(2px);
}


.daysList label {
  display: block;
  margin: 0 10px 0 5px;
}
.daysList {
  margin-left: 3px!important;
  text-align: center;
}